<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-12">
        <base-input
          :value="mail.from"
          :readOnly="true"
          :label="`${$t(`COMMON.MAIL_FROM`)}`"
        />
        <validation-error :errors="apiValidationErrors.to" />
      </div>
      <div class="col-12">
        <base-input
          v-model="mail.to"
          :readOnly="true"
          :label="`${$t(`COMMON.MAIL_TO`)}`"
          :placeholder="$t('COMMON.MAIL_SEPARATE_WITH_COMMA')"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.to" />
      </div>

      <div class="col-12">
        <base-checkbox
          @input="onFormChanged"
          v-model="mail.sendMeCopy"
          class=""
        >
          <span class="form-control-label">
            {{ $t("COMMON.MAIL_SEND_ME_COPY") }}
          </span>
        </base-checkbox>
      </div>
      <div class="col-12">
        <base-input
          v-model="mail.cc"
          :label="$t('COMMON.MAIL_CC')"
          @change="onFormChanged"
          :placeholder="$t('COMMON.MAIL_SEPARATE_WITH_COMMA')"
        />
        <validation-error :errors="apiValidationErrors.cc" />
      </div>
      <div class="col-12">
        <base-input
          v-model="mail.bcc"
          :label="$t('COMMON.MAIL_BCC')"
          @change="onFormChanged"
          :placeholder="$t('COMMON.MAIL_SEPARATE_WITH_COMMA')"
        />
        <validation-error :errors="apiValidationErrors.bcc" />
      </div>
      <div class="col-12">
        <base-input
          v-model="mail.subject"
          :label="`${$t(`COMMON.MAIL_SUBJECT`)} (*)`"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.subject" />
      </div>
      <div class="col-12">
        <h2 class="">{{ $t("COMMON.MAIL_BODY") }}</h2>
        <html-editor v-model="mail.body" @change="onFormChanged"></html-editor>
        <validation-error :errors="apiValidationErrors.body" />
      </div>
    </div>
    <div class="d-flex justify-content-start">
      <el-button :disabled="submitting" @click="handleSubmit" type="success">
        {{ $t("COMMON.SEND") }}
      </el-button>
    </div>
  </form>
</template>
<script>
import { Button, Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import defaultSalesInvoiceMailData from "@/constants/defaultSalesInvoiceMailData";

export default {
  layout: "DashboardLayout",
  name: "documents-send-mail-form",

  components: {
    BaseInput,
    ValidationError,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],
  props: {
    documentData: {
      default: () => {},
    },
    toggleMailModal: {
      type: Function,
    },
    mailInformation: {
      type: Object,
    },
    get: {
      type: Function,
    },
    loading: {
      default: () => true,
    },
    dispatchUrl: {
      type: String,
      default: "salesInvoices/sendMail",
    },
  },

  data() {
    return {
      mail: defaultSalesInvoiceMailData,
      user: null,
      submitting: false,
      formErrors: {},
    };
  },

  created() {},

  mounted() {
    this.getUserInfo();
  },

  methods: {
    async handleSubmit() {
      this.submitting = true;
      let mailData = this.mail;

      if (!mailData?.cc) {
        delete mailData.cc;
      }
      if (!mailData?.bcc?.length) {
        delete mailData.bcc;
      }

      if (!mailData?.body) {
        delete mailData.body;
      }

      try {
        await this.$store.dispatch(this.dispatchUrl, {
          ...mailData,
          id: this.documentData?.id,
        });
        this.$notify({
          type: "success",
          message: this.$t("COMMON.EMAIL_SENT_SUCCESSFULLY"),
        });
        this.submitting = false;
        if (this.toggleMailModal) {
          this.toggleMailModal();
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.submitting = false;
      }
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getUserInfo() {
      await this.$store.dispatch("profile/me");
      this.user = this.$store.getters["profile/me"];
      this.mail = {
        ...this.mail,
        from: `${this.user.firstname} ${this.user.lastname} <${this.user.email}>`,
        to: this.mailInformation?.recipient?.email,
        subject: this.mailInformation?.subject,
        body: this.$t("COMMON.EMAIL_INFOS_BODY", {
          username: this.mailInformation?.recipient?.username,
          sender_name: `${this.user?.firstname} ${this.user?.lastname}`,
          phone: `${
            this.user.phone_type
              ? this.$t(`COMMON.PHONE_TYPE_${this.user.phone_type}`)
              : ""
          }: ${this.user.phone}${
            this.user.phone_extension
              ? ` (ext: ${this.user.phone_extension})`
              : ""
          }`,
          email: this.user?.email,
          organization: this.mailInformation?.organization?.name,
        }),
      };
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
